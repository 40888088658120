import * as React from "react"
import { Link } from "gatsby"
import {Container, Row} from "react-bootstrap"
import { FooterWrapper } from "./Footer.styles"
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";

const Footer = () =>{
    return(
        <FooterWrapper>
        <div className="page-footer font-small blue pt-4">
            <Container>
                <Row>
                    <div className="col-md-3 mt-md-0 mt-3">
                    <h5 className="text-uppercase">Quick Links</h5>
                        <ul className="list-unstyled">
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            <li><Link to="/contact">Contact Us</Link></li>
                        </ul>
                    </div>

                    <hr className="clearfix w-100 d-md-none pb-0"/>

                    <div className="col-md-3 mb-md-0 mb-3">
                    <h5 className="text-uppercase">Support</h5>
                        <ul className="list-unstyled">
                            <li><p>+973 77 360 360</p></li>
                            <li><a href='mailto:info@edxlabs.com'>info@edxlabs.com</a></li>
                        </ul>
                    </div>

                    <div className="col-md-3 mb-md-0 mb-3">
                        <h5 className="text-uppercase">Address</h5>
                        <ul className="list-unstyled">
                            <li><p>
                            <span>21st Floor, Harbour Towers - East, <br />Bahrain Financial Harbour, King Faisal Highway, Building 1398,<br /> Road 4626, Block 346</span> 
                                <span> Manama, Kingdom of Bahrain</span></p></li>
                           
                        </ul>
                    </div>
                    <div className="col-md-3 mt-md-0 mt-3 app-icon">
                    <h5 className="text-uppercase">Follow Us</h5>
                        <ul className="list-unstyled follow-us-list">
                            <li><Link to="https://www.facebook.com/edxlabs/" target="_blank"><FaFacebook/></Link></li>
                            <li><Link to="https://www.linkedin.com/company/edx-labs" target="_blank"><FaLinkedin/></Link></li>
                            <li><Link to="https://twitter.com/teamedxlabs" target="_blank"><FaTwitter/></Link></li>
                            <li><Link to="https://www.youtube.com/channel/UC5uVt3vFBilfnGEok3nUhoA" target="_blank"><FaYoutube/></Link></li>
                            <li><Link to="https://www.instagram.com/edxlabs/" target="_blank"><FaInstagram/></Link></li>
                        </ul>
                    </div>
                </Row>
            </Container>

        <div className="footer-copyright text-center">
            <p> Copyright © 2022 EDXLABS | Powered by EDXLabs</p>
        </div>

        </div>
</FooterWrapper>

    )
}
export default Footer;