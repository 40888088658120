import React from "react"
import { Link } from "gatsby"
import { NavigationWrapper } from "./Navigation.styles"
import { AnimatePresence } from "framer-motion";

const Navigation = () => (
  <NavigationWrapper>
    <AnimatePresence exitBeforeEnter>
    <ul>
    <li><Link to="/">HOME</Link></li>
    <li><Link to="/contact">CONTACT</Link></li>
    
    </ul>
    </AnimatePresence>
  </NavigationWrapper>
)

export default Navigation