import React from "react"
import { Link } from "gatsby"
import Logo from "../../images/edx-logo-white.png"
import CloseButton from "../../images/close_btn.svg"
import { Overlay } from "./OverlayMenu.styles"
import Navigation from "../Navigation/Navigation"

const OverlayMenu = ({ menuOpen, callback }) => {

  return (
    <Overlay menuOpen={menuOpen}>
      <div className="inner">
        <img className="invertedLogo" src={Logo} alt="white-logo" />
        <Navigation/>
        <div
          className="closeButton"
          onClick={callback}
          role="button"
          tabIndex="0"
          onKeyDown={callback}
        >
          <img src={CloseButton} alt="close-button" />
        </div>
      </div>
    </Overlay>
  )
}

export default OverlayMenu