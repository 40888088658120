import styled from 'styled-components';

export const Wrapper = styled.header`
  background: transparent;
  height: 110px;
  width: 100%;
  z-index: 10000;
  position: absolute;
  @media (max-width: 768px) {
    position: relative;
    background : #bc0000;
  }
`

export const Content = styled.div`
display: block;
height: 100%;

  .row{
    width:100%;
  }
  img {
    max-width: 215px;
    width:100%;
    margin: 0;
    @media (max-width: 768px) {
      max-width: 180px;
    }
  }
  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2.5rem;
    position: absolute;
    z-index: 1;
    width: 100%;

  li a{
    color: #fff;
    font-family: "Montserrat", Sans-serif;
    }
  li a:hover{
    color: #bc0000;
  }  
  }
  
  .showBar {
    background: #bc0000;
    position: fixed;
    top: 0;
    left: 0;
    padding: 1rem 2.5rem;
    box-shadow: 1px 1px 21px #2222224a;
    animation: moveDown 0.5s ease-in-out;

    li a{
      color: #fff;
      :hover{
        color: #fff;
      }
      }
    img {
        max-width: 125px;
        width:100%;  
    }
  }

  @keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

`;
